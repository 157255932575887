/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: Montserrat;
  src: url("../fonts/Montserrat-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: Montserrat;
  src: url("../fonts/Montserrat-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Montserrat;
  src: url("../fonts/Montserrat-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: Montserrat;
  src: url("../fonts/Montserrat-Medium.ttf");
  font-weight: 500;
}

body {
  font-size: 16px;
  font-family: 'Work Sans', sans-serif;
  /*
  background: url("./img/background.jpg") no-repeat center fixed;
  background-size: cover;
  */
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-color: #41A62A;
  font-weight: 400;
  overflow-y: scroll;
}

body * {
  transition: 0.2s ease-in-out;
  overflow: visible;
}

body a {
  color: #000;
}

body a:hover {
  color: #231f20;
}

strong {
  font-weight: 700;
}

.container {
  position: relative;
  max-width: 1376px;
  min-height: 100vh;
  box-sizing: border-box;
  margin: auto;
  padding-bottom: 4em;
  background: rgba(255, 255, 255, 0.8);
}

.container.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 9em 2em 1fr;
  padding-bottom: unset;
}

.container > * {
  padding: 0 2rem;
}

.container header {
  padding: 1em 2em;
}

.headercont {
  color: #000;
}

.headercont header {
  max-width: 1376px;
  margin: auto;
  padding: 1em 2em;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.8);
}

.current, .parent {
  overflow: auto;
  border-top: 1px solid #BCBEC0;
  padding-bottom: 4em;
}

.current a, .parent a {
  text-decoration: none;
  color: #808285;
}

.current li, .current .current_name, .parent li, .parent .current_name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
}

.current li > a, .current .current_name > a, .parent li > a, .parent .current_name > a {
  display: flex;
  align-items: center;
}

.current li > a svg, .current .current_name > a svg, .parent li > a svg, .parent .current_name > a svg {
  margin-right: 1em;
}

.current .file_item > svg, .parent .file_item > svg {
  margin-right: 1.8em;
}

#notification {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0;
}

.message, .optional_tools {
  padding: 1em 2em;
  display: flex;
  align-items: center;
  width: max-content;
}

.message a, .message p, .optional_tools a, .optional_tools p {
  color: #fff;
}

.message > svg, .optional_tools > svg {
  margin-right: 1em;
  width: 1.5em;
}

.title {
  display: flex;
  align-items: flex-start;
  text-decoration: none;
}

.title svg {
  height: 2.5em;
  margin-right: .2em;
}

.small {
  font-size: 0.8em;
}

#messages {
  display: none;
  opacity: 0;
  transition: .3s all ease-in-out;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.error {
  background: #F07464;
}

.warning {
  background: #F79D5D;
}

.success {
  background: #76CF9A;
}

.optional_tools {
  background: #58595B;
}

.optional_tools .separator {
  color: #fff;
  margin: 0 .5em;
}

svg {
  height: 2rem;
}

.action {
  cursor: pointer;
}

.action svg {
  height: 1.5em;
  width: 1.5em;
  margin-left: 1em;
}

.action svg path {
  fill: #808285;
}

h2 {
  color: #27AAE1;
  font-size: 1.5em;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
}

header {
  grid-column: 1 / 5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  flex-wrap: wrap;
}

header > * {
  display: flex;
  align-items: center;
}

.avatar-container {
  border: 1px dashed #AAA;
  font-size: 2em;
  height: 2em;
  width: 2em;
  border-radius: 3em;
  margin-left: 2em;
  text-align: center;
  padding: 3px;
  margin-top: -3px;
}

.avatar {
  height: 2em;
  width: 2em;
  border-radius: 3em;
  color: #FFF;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: transparent;
  line-height: 1.95em;
}

h1 {
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  align-items: flex-start;
}

h1 i {
  font-style: italic;
  margin-right: .15em;
}

.version {
  font-size: .8em;
  font-weight: 400;
}

.user p {
  margin-left: 2em;
}

#tools {
  grid-column: 2/5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2em;
}

#tools #close_tools {
  display: none;
}

#uploader, #mobile_uploader {
  display: flex;
  position: relative;
  height: 100%;
  width: 20em;
}

#uploader input, #mobile_uploader input {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  top: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
  height: 100%;
}

#uploader .dashes, #mobile_uploader .dashes {
  padding: .5em;
  border: 1px dashed #27AAE1;
  border-radius: 7em;
  transition: .3s ease-out;
}

#uploader .size, #mobile_uploader .size {
  color: #27AAE1;
  margin-top: .2em;
}

#uploader .upload_ux, #mobile_uploader .upload_ux {
  height: 4em;
  width: 4em;
  background: #27AAE1;
  border-radius: 7em;
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

#uploader .upload_ux svg, #mobile_uploader .upload_ux svg {
  height: 100%;
  width: 100%;
}

#uploader .upload_ux svg *, #mobile_uploader .upload_ux svg * {
  transition: .3s ease-in-out;
}

#uploader #upload-svg, #mobile_uploader #upload-svg {
  display: block;
}

#uploader #loading-svg, #mobile_uploader #loading-svg {
  display: none;
}

#uploader.sending #upload-svg, #mobile_uploader.sending #upload-svg {
  display: none;
}

#uploader.sending #loading-svg, #mobile_uploader.sending #loading-svg {
  display: block;
}

#uploader #uploader_item:hover ~ .dashes, #mobile_uploader #uploader_item:hover ~ .dashes {
  border: 1px solid #27AAE1;
}

#uploader #uploader_item:hover ~ .dashes .drive, #mobile_uploader #uploader_item:hover ~ .dashes .drive {
  stroke-width: 2px;
  stroke: #ffffff;
}

#uploader #uploader_item:hover ~ .dashes .upload_arrow, #mobile_uploader #uploader_item:hover ~ .dashes .upload_arrow {
  transform: rotateX(90deg) translateY(0.5em);
}

#uploader #uploader_item:hover ~ .dashes .doc, #mobile_uploader #uploader_item:hover ~ .dashes .doc {
  transform: scale(1.2) translateY(-0.4em);
  transform-origin: center;
}

#uploader .right-part, #mobile_uploader .right-part {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  max-width: 14em;
}

#uploader label, #mobile_uploader label {
  color: #27AAE1;
  text-align: center;
  align-self: center;
  padding: 2em 1em 0;
  line-height: 1.5;
  word-break: break-all;
}

#uploader button, #mobile_uploader button {
  font-family: 'Work Sans', sans-serif;
  font-size: 1em;
  color: #27AAE1;
  margin-top: .5em;
  background: none;
  opacity: 0;
  border: none;
  transition: .3s ease-in-out;
  cursor: pointer;
}

.loading {
  animation: loading 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transform-origin: center;
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fil_arriane {
  grid-column: 1/5;
  display: flex;
  align-items: center;
}

.fil_arriane a {
  color: #000;
  text-decoration: none;
}

.fil_arriane svg {
  height: 1.3em;
  width: 1em;
}

.fil_arriane li:after {
  content: '/';
  margin: 0 .75em;
}

.fil_arriane li:first-of-type:after, .fil_arriane li:last-of-type:after {
  content: '';
}

.fil_arriane li:last-of-type {
  font-weight: bold;
}

.fil_arriane li:last-of-type a {
  color: #27AAE1;
}

.parent {
  grid-column: 1 / 2;
  background: #F1F2F2;
}

.parent li {
  padding-left: 2em;
  background: url("./img/path.svg") 0.2em 0 no-repeat;
  background-size: contain;
}

.parent li:last-of-type {
  background: url("./img/path_end.svg") 0.2em 0 no-repeat;
}

.parent .foldertype:hover svg * {
  fill: #27AAE1;
  stroke: transparent;
}

.current {
  grid-column: 2/ 5;
  padding: 0 0 4em 0;
}

.current .current_name {
  justify-content: start;
}

.current .current_name .mobile_parent_icon {
  display: none;
  margin-right: 1rem;
}

.current .current_name .mobile_parent_icon svg {
  height: auto;
  width: 1em;
}

.current li, .current .current_name {
  padding: 0 2rem;
  border-bottom: 1px solid #BCBEC0;
}

.current .filetype:hover svg .cls-fl-1 {
  fill: #231f20;
  stroke: transparent;
}

.current .foldertype:hover svg * {
  fill: #808285;
  stroke: transparent;
}

#makedir, #mobile_makedir {
  display: flex;
  align-items: center;
}

#makedir > svg, #mobile_makedir > svg {
  height: 3.5em;
  width: 3.5em;
}

#makedir input, #mobile_makedir input {
  background: transparent;
  color: #27AAE1;
  font-family: 'Work Sans', sans-serif;
  font-size: 1em;
  border: none;
  margin-left: 1em;
  border-bottom: 1px solid #27AAE1;
  font-weight: 500;
  transition: 0.2s ease-in-out;
}

#makedir button, #mobile_makedir button {
  background: none;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
}

#makedir:hover input, #makedir input:focus {
  padding-bottom: .5em;
}

.filetype form, .foldertype form {
  display: flex;
}

.filetype form input[type=text], .foldertype form input[type=text] {
  font-family: 'Work Sans', sans-serif;
  font-size: 1em;
  border: none;
  background: none;
  color: #27AAE1;
  border-bottom: 1px solid #27AAE1;
}

.filetype form button[type=submit], .foldertype form button[type=submit] {
  border: none;
  color: #27AAE1;
  font-size: 1.5em;
  background: none;
  cursor: pointer;
}

.filetype form svg {
  width: 1.8em;
  margin-right: 1.6em;
}

.foldertype form svg {
  width: 2em;
  margin-right: 1.5em;
}

.folder_suppresser:hover .couvercle, .file_suppresser:hover .couvercle {
  transform: rotate(55deg);
  transform-origin: 100% 30%;
}

#options:hover {
  transform: rotate(25deg);
}

#options:hover svg * {
  fill: #313131;
}

.login_page .container {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.login_page .title {
  margin: 2em 0 1em;
  display: flex;
  justify-content: center;
  padding-bottom: 3rem;
}

.login_page h1 {
  color: #27AAE1;
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 2em;
  text-align: center;
}

.login_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.login_form label {
  color: #808285;
  font-size: 1em;
  margin-bottom: .5em;
}

.login_form input {
  background: #ffffff;
  border-radius: .3em;
  width: 15em;
  padding: .5em 1em;
  margin-bottom: 1em;
  border: 1px solid #7c7c7c;
  font-family: "Work Sans", sans-serif;
  font-size: 1em;
}

.login_form button {
  background: none;
  color: #27AAE1;
  border: none;
  margin: 1em;
  font-size: 1em;
  font-family: 'Work Sans', sans-serif;
  cursor: pointer;
}

.login_form button:hover {
  color: #155574;
}

#tools_button {
  display: none;
  position: fixed;
  height: 3em;
  width: 3em;
  bottom: 1em;
  right: 1em;
  cursor: pointer;
}

@keyframes bump {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

.tooltipmenucontainer {
  position: relative;
}

.tooltipmenucontainer .tooltipmenu {
  position: absolute;
  width: 200px;
  left: -84px;
  padding-top: 10px;
  z-index: -1;
  opacity: 0;
  transform: translate(0, -10px);
  transition: opacity 0.3s, transform 0.3s;
}

.tooltipmenucontainer .tooltipmenu.open {
  opacity: 1;
  z-index: 3;
  transform: translate(0, 0);
}

.tooltipmenucontainer .tooltipmenu span.arrow.up {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 16px;
  margin: auto;
  background: transparent url("./img/arrow-up.png") center top no-repeat;
  display: block;
  height: 11px;
}

.tooltipmenucontainer .tooltipmenu ul {
  background: #FFF;
  border: 1px solid #D9DADB;
  padding: 0 14px 0.2rem;
  font-size: 14px;
}

.tooltipmenucontainer .tooltipmenu ul li {
  border-top: 1px solid #87888A;
}

.tooltipmenucontainer .tooltipmenu ul li a {
  display: block;
  padding: 0.8rem 0 0.5rem;
  color: #BBB;
  text-decoration: none;
}

.tooltipmenucontainer .tooltipmenu ul li a[href] {
  color: #87888A;
}

.tooltipmenucontainer .tooltipmenu ul li a[href]:hover, .tooltipmenucontainer .tooltipmenu ul li a[href].active {
  color: #00AEEF;
}

.tooltipmenucontainer .tooltipmenu ul li:first-child {
  border-top: none;
}

header .user > .tooltipmenucontainer > a {
  display: block;
  width: 32px;
  height: 32px;
}

@media screen and (max-width: 1510px) {
  .user.anon .tooltipmenucontainer .tooltipmenu {
    left: auto;
    right: 0;
  }
  .user.anon .tooltipmenucontainer .tooltipmenu span.arrow.up {
    left: auto;
    right: 8px;
  }
}

@media screen and (max-width: 764px) {
  .user .tooltipmenucontainer .tooltipmenu {
    left: auto;
    right: 0;
  }
  .user .tooltipmenucontainer .tooltipmenu span.arrow.up {
    left: auto;
    right: 8px;
  }
}

#search {
  background: #ffffff;
  border-radius: .1em;
  width: 30%;
  display: flex;
  justify-content: space-between;
  min-width: 17em;
}

#search input {
  padding: .5em 1em;
  font-family: 'Work Sans', sans-serif;
  font-weight: 500;
  background: none;
  color: #7c7c7c;
  border: none;
  width: 100%;
}

#search button {
  background: none;
  padding: .5em 1em;
  border: none;
  border-left: 1px solid #b5b5b5;
  border-radius: 0 .3em .3em 0;
  cursor: pointer;
}

#search button:hover {
  background: rgba(175, 169, 170, 0.18);
}

#search button svg {
  height: 1.5em;
}

.form-group {
  padding-bottom: 1em;
}

.form-group > * {
  display: block;
  margin-bottom: 1em;
}

.form-group input {
  color: #7c7c7c;
  background: #ffffff;
  border-radius: .1em;
  border: 1px solid #CCCCCC;
  padding: .5em 1em;
  font-family: 'Work Sans', sans-serif;
  font-weight: 500;
}

.form-group p {
  margin-bottom: 1em;
}

.file-input {
  position: relative;
  margin-top: 1em;
  /*
  input[type='file'] {
    position: absolute;
    left: 0;
    top: 0;
    width: 100px;
    opacity: 0;
    cursor: pointer;
  }*/
}

.bgchoice {
  display: inline-block;
  vertical-align: top;
  padding-right: 1em;
}

.bgchoice input {
  float: left;
  margin-left: 0;
  margin-bottom: 0.3em;
}

.bgchoice img {
  width: 100px;
  display: block;
  clear: both;
  cursor: pointer;
}

.btn {
  background: #39B54A;
  color: #FFF;
  border: none;
  font-size: 1em;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-family: 'Work Sans', sans-serif;
  font-weight: 500;
  min-width: 10em;
}

.btn:hover {
  background: #2d8e3a;
}

.btn > span {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.btn > span span {
  flex-grow: 1;
  margin-right: 1em;
}

.btn > span svg {
  height: 1em;
  margin: 0.7em 1em;
}

.btn > span svg path {
  fill: #FFF;
}

button.btn {
  padding: 0;
}

a.btn {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

body.hp {
  background: #41A62A !important;
  color: #FFF;
}

body.hp .headercont {
  background: #FFF;
}

body.hp a {
  color: #FFF;
}

body.hp .container {
  background: transparent;
}

body.hp .homeblock {
  padding-top: 4rem;
  clear: both;
  font-size: 19px;
  line-height: 1.4em;
}

body.hp .homeblock .text {
  float: left;
  width: calc(70% - 4px);
  box-sizing: border-box;
}

body.hp .homeblock .text h2, body.hp .homeblock .text h3, body.hp .homeblock .text .h2, body.hp .homeblock .text .h3 {
  margin-top: 0;
  height: auto;
  color: #FFF;
  font-weight: normal;
}

body.hp .homeblock .text h2, body.hp .homeblock .text .h2 {
  font-size: 32px;
}

body.hp .homeblock .text h3, body.hp .homeblock .text .h3 {
  font-size: 27px;
  text-decoration: underline;
}

body.hp .homeblock .text h2, body.hp .homeblock .text p {
  margin-bottom: 2rem;
}

body.hp .homeblock .text h1 {
  font-weight: normal;
  font-size: 3.4rem;
  line-height: 4rem;
  display: block;
}

body.hp .homeblock .illustration {
  float: left;
  width: 30%;
  box-sizing: border-box;
  padding-left: 10%;
}

body.hp .homeblock .illustration img, body.hp .homeblock .illustration svg {
  display: block;
  width: 100%;
  height: auto;
}

body.hp .homeblock.first .text {
  width: calc(60% - 4px);
  padding-right: 10%;
}

body.hp .homeblock.first .text svg {
  height: 4rem;
  margin-bottom: 2rem;
}

body.hp .homeblock.first .text svg .stl1 {
  fill: #FFF;
}

body.hp .homeblock.first .text .titles {
  padding-bottom: 3rem;
}

body.hp .homeblock.first .illustration {
  width: 40%;
  padding-bottom: 4em;
  padding-left: 0;
}

body.hp .homeblock:after {
  clear: both;
  display: block;
  content: "";
}

body.hp form input[type="text"], body.hp form input[type="email"] {
  background: none;
  color: #FFF;
  border: 2px solid #81CA8B;
  border-width: 0 0 2px 0;
  font-size: 1em;
  margin-right: 1em;
  margin-bottom: 1em;
}

body.hp form button:not(.btn) {
  border: 2px solid white;
  background: none;
  color: #FFF;
  padding: 1px 1em;
  cursor: pointer;
}

body.hp form button.btn {
  padding: 0.5em 2em;
}

body.hp p.result {
  padding-top: 1em;
}

body.hp p.result.ok {
  color: #43E0BD;
}

body.hp p.result.ko {
  color: #E5352D;
}

@media screen and (max-width: 1024px) {
  body.hp .homeblock .text {
    width: calc(60% - 4px);
  }
  body.hp .homeblock .text h1 {
    font-size: 2rem;
    line-height: 2.5rem;
  }
  body.hp .homeblock .illustration {
    width: 40%;
  }
  body.hp .homeblock.first .text {
    width: calc(50% - 4px);
    margin: 0;
  }
  body.hp .homeblock.first .illustration {
    width: 50%;
  }
}

@media screen and (max-width: 764px) {
  body.hp .homeblock .text {
    float: none;
    margin: auto;
    width: auto;
  }
  body.hp .homeblock .illustration {
    float: none;
    margin: auto;
    padding: 0;
    width: 70%;
    max-width: 200px;
  }
  body.hp .homeblock.first .text {
    float: none;
    margin: auto;
    width: auto;
    padding-bottom: 0;
  }
  body.hp .homeblock.first .illustration {
    float: none;
    margin: auto;
    padding: 0;
    width: 70%;
    max-width: 200px;
  }
}

@media screen and (max-width: 1023px) {
  #search {
    order: 3;
  }
  header .user {
    flex-basis: 50%;
    flex-grow: 1;
    justify-content: flex-end;
  }
  header .title {
    flex-basis: 50%;
    flex-grow: 1;
  }
  .switch {
    display: none;
  }
  #tools {
    grid-column: 1/5;
  }
  .parent {
    display: none;
  }
  .current {
    grid-column: 1/5;
  }
  .optional_tools {
    display: none;
  }
  .current .current_name .mobile_parent_icon {
    display: block;
  }
}

@media screen and (max-width: 764px) {
  .container {
    display: block;
    /*.fil_arriane {
      padding: 0 1em 1em;
    }*/
  }
  .container.grid {
    grid-template-rows: 2em 1fr;
  }
  .container > * {
    padding: 1em;
  }
  .container header {
    padding: 1em;
  }
  .container .current {
    padding: 0;
  }
  header {
    /*
    flex-direction: column;
    align-items: start;
    */
    border-bottom: none;
  }
  header .title {
    flex-basis: 50%;
    flex-grow: 0.5;
  }
  header .user {
    flex-basis: 50%;
    flex-grow: 1;
  }
  .current li, .current .current_name {
    padding: 0 1rem;
  }
  #search {
    width: 100%;
    min-width: 0;
    margin-top: 2em;
  }
  #options {
    /*
    position: absolute;
    top: 1em;
    right: 1em;
    */
  }
  .user_element {
    display: none;
  }
  #tools {
    position: fixed;
    bottom: 0;
    transform: translateY(100%);
    max-height: 25em;
    min-height: 50vh;
    box-sizing: border-box;
    width: 100vw;
    background: #fff;
    transition: .5s ease-in-out;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  #tools #close_tools {
    display: block;
    background: #27AAE1;
    height: 2em;
    width: 2em;
    position: relative;
    transform: rotate(45deg) translateY(-1.2em);
    transform-origin: top;
  }
  #uploader {
    flex-direction: column;
    align-items: center;
  }
  .dashes {
    width: 6em;
  }
  #tools.open {
    transform: translateY(0);
  }
  #tools_button {
    display: block;
  }
  .current li {
    padding-left: 2.5em;
  }
  .current li svg {
    width: 1.5em;
    height: auto;
  }
  .current li svg * {
    fill: #808285;
    stroke: transparent;
  }
}

@media screen and (max-width: 500px) {
  header .user {
    flex-basis: 32px;
  }
}
