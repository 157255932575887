
#search {
  background: #ffffff;
  border-radius: .1em;
  width: 30%;
  display: flex;
  justify-content: space-between;
  min-width: 17em;

  input {
    padding: .5em 1em;
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
    background: none;
    color: #7c7c7c;
    border: none;
    width: 100%;
  }

  button {
    background: none;
    padding: .5em 1em;
    border: none;
    border-left: 1px solid #b5b5b5;
    border-radius: 0 .3em .3em 0;
    cursor: pointer;

    &:hover {
      background: rgba(175, 169, 170, 0.18);
    }

    svg {
      height: 1.5em;
    }
  }
}

.form-group {
  padding-bottom: 1em;
  & > * {
    display: block;
    margin-bottom: 1em;
  }
  input {
    color: #7c7c7c;
    background: #ffffff;
    border-radius: .1em;
    border: 1px solid #CCCCCC;
    padding: .5em 1em;
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
  }
  p {
    margin-bottom: 1em;
  }
}

.file-input {
  position: relative;
  margin-top: 1em;
  /*
  input[type='file'] {
    position: absolute;
    left: 0;
    top: 0;
    width: 100px;
    opacity: 0;
    cursor: pointer;
  }*/
}

.bgchoice {
  display: inline-block;
  vertical-align: top;
  padding-right: 1em;
  input {
    float: left;
    margin: {
      left: 0;
      bottom: 0.3em;
    }
  }
  img {
    width: 100px;
    display: block;
    clear: both;
    cursor: pointer;
  }
}

.btn {
  background: #39B54A;
  color: #FFF;
  border: none;
  font-size: 1em;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-family: 'Work Sans', sans-serif;
  font-weight: 500;
  //width: 140px;
  min-width: 10em;
  &:hover {
    background: darken(#39B54A, 10%);
  }
  & > span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    span {
      flex-grow: 1;
      margin-right: 1em;
    }
    svg {
      height: 1em;
      margin: 0.7em 1em;
      path {
        fill: #FFF;
      }
    }
  }
}
button.btn  {
  padding: 0;
}
a.btn {
  padding: {
    top: 0.5em;
    bottom: 0.5em;
  }
}