
@media screen and (max-width: 764px) {

  .container {
    display: block;
    &.grid {
      grid-template-rows: 2em 1fr;
    }

    > * {
      padding: 1em;
    }

    header {
      padding: 1em;
    }

    .current {
      padding: 0;
    }

    /*.fil_arriane {
      padding: 0 1em 1em;
    }*/

  }

  header {
    /*
    flex-direction: column;
    align-items: start;
    */
    border-bottom: none;

    .title {
      //margin-bottom: 2em;
      flex-basis: 50%;
      flex-grow: 0.5;
    }
    .user {
      flex-basis: 50%;
      flex-grow: 1;
    }
  }

  .current li, .current .current_name {
    padding: 0 1rem;
  }

  #search {
    width: 100%;
    min-width: 0;
    margin-top: 2em;
  }

  #options {
    /*
    position: absolute;
    top: 1em;
    right: 1em;
    */
    svg {
      //height: 1.5em;
    }
  }

  .user_element {
    display: none;
  }

  #tools {
    position: fixed;
    bottom: 0;
    transform: translateY(100%);
    max-height: 25em;
    min-height: 50vh;
    box-sizing: border-box;
    width: 100vw;
    background: #fff;
    transition: .5s ease-in-out;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    #close_tools {
      display: block;
      background: $mainColor;
      height: 2em;
      width: 2em;
      position: relative;
      transform: rotate(45deg) translateY(-1.2em);
      transform-origin: top;
    }
  }

  #uploader {
    flex-direction: column;
    align-items: center;
  }

  .dashes {
    width: 6em;
  }

  #tools.open {
    transform: translateY(0);
  }

  #tools_button {
    display: block;
  }

  .current li {
    padding-left: 2.5em;

    svg {
      width: 1.5em;
      height: auto;

      * {
        fill: #808285;
        stroke: transparent;
      }
    }
  }

}
