body.hp {
  background: #41A62A !important;
  color: #FFF;
  .headercont {
    background: #FFF;
  }
  a {
    color: #FFF;
  }
  .container {
    background: transparent;
  }
  .homeblock {
    padding-top: 4rem;
    clear: both;
    font-size: 19px;
    line-height: 1.4em;
    .text {
      float: left;
      width: calc(70% - 4px);
      box-sizing: border-box;
      h2, h3, .h2, .h3 {
        margin-top: 0;
        height: auto;
        color: #FFF;
        font-weight: normal;
      }
      h2, .h2 {
        font-size: 32px;
      }
      h3, .h3 {
        font-size: 27px;
        text-decoration: underline;
      }
      h2, p {
        margin-bottom: 2rem;
      }
      h1 {
        font-weight: normal;
        font-size: 3.4rem;
        line-height: 4rem;
        display: block;
      }
    }
    .illustration {
      float: left;
      width: 30%;
      box-sizing: border-box;
      padding-left: 10%;
      img, svg {
        display: block;
        width: 100%;
        height: auto;
      }
    }
    &.first {
      .text {
        width: calc(60% - 4px);
        //padding-bottom: 4em;
        padding-right: 10%;
        svg {
          height: 4rem;
          margin-bottom: 2rem;
          .stl1 {
            fill: #FFF;
          }
        }
        .titles {
          padding-bottom: 3rem;
        }
      }
      .illustration {
        width: 40%;
        padding-bottom: 4em;
        padding-left: 0;
      }
    }
    &:after {
      clear: both;
      display: block;
      content: "";
    }
  }
  form {
    input[type="text"], input[type="email"] {
      background: none;
      color: #FFF;
      border: 2px solid #81CA8B;
      border-width: 0 0 2px 0;
      font-size: 1em;
      margin-right: 1em;
      margin-bottom: 1em;
    }
    button:not(.btn) {
      border: 2px solid white;
      background: none;
      color: #FFF;
      padding: 1px 1em;
      cursor: pointer;
    }
    button.btn {
      padding: 0.5em 2em;
    }
  }
  p.result {
    padding-top: 1em;
    &.ok {
      color: #43E0BD;
    }
    &.ko {
      color: #E5352D;
    }
  }
}

@media screen and (max-width: 1024px) {
  body.hp {
    .homeblock {
      .text {
        width: calc(60% - 4px);
        h1 {
          font-size: 2rem;
          line-height: 2.5rem;
        }
      }
      .illustration {
        width: 40%;
      }
      &.first {
        .text {
          width: calc(50% - 4px);
          margin: 0;
        }
        .illustration {
          width: 50%;
        }
      }
    }
  }
}

@media screen and (max-width: 764px) {
  body.hp {
    .homeblock {
      .text {
        float: none;
        margin: auto;
        width: auto;
      }
      .illustration {
        float: none;
        margin: auto;
        padding: 0;
        width: 70%;
        max-width: 200px;
      }
      &.first {
        .text {
          float: none;
          margin: auto;
          width: auto;
          padding-bottom: 0;
        }
        .illustration {
          float: none;
          margin: auto;
          padding: 0;
          width: 70%;
          max-width: 200px;
        }
      }
    }
  }
}