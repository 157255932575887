
.tooltipmenucontainer {
  position: relative;

  .tooltipmenu {
    position: absolute;
    width: 200px;
    left: -84px;
    padding-top: 10px;
    z-index: -1;
    opacity: 0;
    transform: translate(0, -10px);
    transition: opacity 0.3s, transform 0.3s;
    &.open {
      opacity: 1;
      z-index: 3;
      transform: translate(0, 0);
    }
    span.arrow.up {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 16px;
      margin: auto;
      background: transparent url("./img/arrow-up.png") center top no-repeat;
      display: block;
      height: 11px;
    }
    ul {
      background: #FFF;
      border: 1px solid #D9DADB;
      padding: 0 14px 0.2rem;
      font-size: 14px;
      li {
        border-top: 1px solid #87888A;
        a {
          display: block;
          padding: 0.8rem 0 0.5rem;
          color: #BBB;
          text-decoration: none;
          &[href] {
            color: #87888A;
            &:hover, &.active {
              color: #00AEEF;
            }
          }
        }
        &:first-child {
          border-top: none;
        }
      }
    }
  }
}

header .user > .tooltipmenucontainer {
  & > a {
    display: block;
    width: 32px;
    height: 32px;
  }
}

@media screen and (max-width: 1510px) { // this is where the settings button begins to get close to the border
  .user.anon {
    .tooltipmenucontainer {
      .tooltipmenu {
        left: auto;
        right: 0;

        span.arrow.up {
          left: auto;
          right: 8px;
        }
      }
    }
  }
}

@media screen and (max-width: 764px) { // this is where the settings button begins to get close to the border
  .user {
    .tooltipmenucontainer {
      .tooltipmenu {
        left: auto;
        right: 0;

        span.arrow.up {
          left: auto;
          right: 8px;
        }
      }
    }
  }
}